import PropTypes from 'prop-types';
import {
  NavLink,
  useMatch
} from 'react-router'

function ShareNavigator({shareName, shareId}) {
  const feedMatch = useMatch({path: "/feeds/:uid.html"});
  const editMatch = useMatch({path: "/shares/:id/edit"});

  return (
    <nav className="bg-white border-b border-gray-200 flex lg:-mt-7" aria-label="Breadcrumb">
      <ol className="max-w-(--breakpoint-xl) w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
        <li className="flex">
          <div className="flex items-center">
            <a href="/" className="text-gray-400 hover:text-gray-500">
              <svg className="shrink-0 h-5 w-5 transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              <span className="sr-only">Shares</span>
            </a>
          </div>
        </li>
        <li className="flex">
          <div className="flex items-center space-x-4">
            <svg className="shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <NavLink to={`/shares/${shareId}`} end className={({isActive}) => `${isActive ? "cursor-default" : ""} text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out`}>{shareName || "Unconfigured share"}</NavLink>
          </div>
        </li>
        {feedMatch && (
          <li className="flex">
            <div className="flex items-center space-x-4">
              <svg className="shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <NavLink to={feedMatch.pathname} className={({isActive}) => `${isActive ? "cursor-default" : ""} text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out`}>Calendar</NavLink>
            </div>
          </li>
        )}
        {editMatch && (
          <li className="flex">
            <div className="flex items-center space-x-4">
              <svg className="shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <NavLink to={editMatch.pathname} className={({isActive}) => `${isActive ? "cursor-default" : ""} text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out`}>Edit</NavLink>
            </div>
          </li>
        )}
      </ol>
    </nav>
  )
}

ShareNavigator.propTypes = {
  shareName: PropTypes.string,
  shareId: PropTypes.string.isRequired
};

export default ShareNavigator;
