import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogBackdrop, DialogTitle, DialogPanel } from '@headlessui/react';

function Modal({title, submitModal, children, open, closeModal, controlsEnabled = true}) {
  return (
    <Dialog open={open} onClose={closeModal} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-closed:opacity-0 data-enter:duration-300 data-leave:duration-200 data-enter:ease-out data-leave:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
          <DialogPanel
            transition
            className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-closed:translate-y-4 data-closed:opacity-0 data-enter:duration-300 data-leave:duration-200 data-enter:ease-out data-leave:ease-in sm:my-8 w-full md:max-w-3xl sm:p-6 sm:data-closed:translate-y-0 sm:data-closed:scale-95"
          >
            <div className="mt-3 sm:mt-5">
              <DialogTitle as="h3" className="text-base text-center font-semibold text-gray-900">{title}</DialogTitle>
              {children}
            </div>
            {submitModal ? (
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  disabled={!controlsEnabled}
                  onClick={submitModal}
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                >
                  Submit
                </button>
                <button
                  type="button"
                  disabled={!controlsEnabled}
                  onClick={closeModal}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={closeModal}
                  disabled={!controlsEnabled}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                >
                  Close
                </button>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitModal: PropTypes.func,
  children: PropTypes.node.isRequired,
  controlsEnabled: PropTypes.bool
};

export default Modal;
